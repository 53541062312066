<template>
	<div>
		<van-image
		  width="100%"
		  height="255px"
		  fit="cover"
		  :src="img"
		  style="display: block;"
		/>
		<van-cell-group  class="from-group-item goods_top" style="display: flex;justify-content:space-between">
			<van-cell style="border-bottom: none;">
				<div style="font-size: 16px;" class="more-t">{{goods_info.good_name}}</div>
				<div style="margin-top: 15px;">
					<span style="font-size:20px;color: #ed1c24;font-weight: bold;">￥{{goods_info.groupbuy_price}}</span>
					<span style="font-size:12px;text-decoration:line-through;color: #aaa;">￥{{goods_info.alone_price}}</span>
				</div>
			</van-cell>
		</van-cell-group>
		<van-cell-group  class="from-group-item" style="margin: 10px 0;">
			<van-cell>
				<van-count-down :time="goods_info.enddate" format="距结束：DD 天 HH 时 mm 分 ss 秒" style="color: #ed1c24"/>
				<div style="">{{goods_info.minnumber}}人成团</div>
				<div style="color: grey;">{{goods_info.description}}</div>
			</van-cell>
			
		</van-cell-group>
		
		<van-cell-group  class="from-group-item" style="margin: 10px 0;">
			<van-cell title="数量">
				<van-stepper label="数量" v-model="num" :step="1" :min="1"/>
			</van-cell>
		</van-cell-group>
		
		<van-cell-group style="margin-bottom: 10px;">
			<van-cell>
				<div style="color: grey;">一起拼拼</div>
			</van-cell>
			<van-cell v-for="(item, index) in goods_info.group" :key="index">
				<div style="display: flex;justify-content: space-between;align-items: center;">
				<div style="display: flex;align-items: center;">
					<van-image round :src="item.user.avatar" fit="cover" width="1rem" height="1rem"></van-image>
					<div style="margin-left: 5px;width:3.5rem;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{item.user.nickname}}</div>
				</div>
				<div style="display: flex; align-items: center;font-size:12px;">
					<div style="text-align: right;line-height: 1;">
						<div style="color: red;">就差 {{item.left}} 个</div>
						<div style="display: flex;align-items: center;">
						<span style="color: gray;">剩余</span>
						<van-count-down :time="item.endtime" format="DD天HH:mm:ss" style="color: #FD9800;"/>
						</div>
					</div>
					<div style="background-color: #FE0000;color: white;margin-left: 5px;padding: 2px 15px;" @click="pin(item.group_id)">拼!</div>
				</div>
				</div>
			</van-cell>
		</van-cell-group>
		
		<van-cell-group  class="from-group-item" style="margin-bottom: 70px;">
			<van-cell>
				<div style="color: grey;">商品详情</div>
				<div v-html="goods_info.content" class="detail"></div>
			</van-cell>
		</van-cell-group>
		
		<!-- 占位使用 -->
		<div style="margin-top: 2rem;"></div>
		
		<div  class="from-group-item group-detail-bottom">
				<div class="group-detail-bottom-item" @click="toactivity()">
					<div><i class="iconfont icon-lvzhou_gengduo_xiangqing"></i></div>
					<div>活动详情</div>
				</div>
				<div class="group-detail-bottom-item" @click="myorder()">
					<div><i class="iconfont icon-pintuangou"></i></div>
					<div>拼团订单</div>
				</div>
				<div class="group-detail-bottom-button" :style="goods_info.stock<1?'background-color: gray;':'background-color: #fd9800;'" @click="buy()">
					<div>￥{{goods_info.alone_price}}</div>
					<div>{{1>goods_info.stock?'已抢完':'单买'}}</div>
				</div>
				<div class="group-detail-bottom-button" :style="goods_info.stock<1?'background-color: gray;':'background-color: #fe0000;'" @click="group()">
					<div>￥{{goods_info.groupbuy_price}}</div>
					<div>{{1>goods_info.stock?'已抢完':'发起拼团'}}</div>
				</div>
		</div>
	</div>
</template>

<script>
import {
        Field,
        Row,
        Col,
        Cell,
        CellGroup,
        Button,
        Toast,
		Stepper,
		Image,
		CountDown
    } from "vant";
	import { activity_detail, groupbuy_user_getgoodsdetail } from '../../../../request/api.js'
export default {
	components: {
	    [Field.name]: Field,
	    [Row.name]: Row,
	    [Col.name]: Col,
	    [Cell.name]: Cell,
	    [CellGroup.name]: CellGroup,
	    [Button.name]: Button,
		[Stepper.name]: Stepper,
		[Image.name]: Image,
		[CountDown.name]: CountDown
	},
	data () {
		return {
			goods_id: 0,
			goods_info: {},
			img: '',
			activity_id: '',
			is_enroll: 0,
			num: 1,
			
			share: 0,
			group_id: 0
		}
	},
	mounted: function() {
		this.goods_id = this.$route.query.id;
		this.activity_id = this.$route.query.activity_id
		this.share = this.$route.query.share
		this.group_id = this.$route.group_id
		this.initData();
		this.loadData()
	},
	methods: {
		// 获取 用户是否报名
		loadData() {
			activity_detail({
				activity_id: this.activity_id,
				member_id: 0,
				superior: 0
			}).then(res => {
				this.is_enroll = res.data.is_enroll
			}).catch(err => {})
		
		},
		// 获取商品详情
		initData() {
			 groupbuy_user_getgoodsdetail({
				 goods_id: this.goods_id
			}).then(res => {
				if (res.code == 1) {
					this.goods_info = res.data;
					this.img = res.data.images[0]
				} else {
					this.$dialog({
						title: '提示',
						message: res.msg
					}).then(g => {
						this.$router.go(-1);
					});
				}
			}).catch(error => {
				this.$dialog({
					title: '提示',
					message: "访问出错"
				}).then(g => {
					this.$router.go(-1);
				});
			});
		},
		// 用于检查 库存 和 用户报名情况
		my_check(){
			// 库存为零
			if (this.goods_info.stock==0 || this.goods_info.stock < 1) {
				this.$dialog({message: '商品已被抢完'})
				return false
			}
			if (this.num > this.goods_info.stock) {
				this.$dialog({message: '购买数量超过商品现有库存'})
				return false
			}
			// 没报名 就前往报名在回来
			if (!this.is_enroll > 0) {
				this.$dialog({
					message: '填写信息，确认购买'
				}).then(res => {
					sessionStorage.setItem('pingtuan',this.$route.fullPath) //保存路径
					this.$router.push({
						path: "/activity/enroll",
						query: {
							id: this.activity_id,
							back: 2
						}
					});
				}).catch(err => {})
				return false
			}
			return true
		},
		buy(){
			if (this.my_check() == false) {
				return false
			}
			this.$router.push({
				path: '/activity/marketing/group/toOrder',
				query: {
					id: this.goods_info.id,
					activity_id: this.activity_id,
					num: this.num,
					type: 0 //单买
				}
			})
		},
		group(group_id = ''){
			if (this.my_check() == false) {
				return false
			}
			if (group_id == ''){
				this.$router.push({
					path: '/activity/marketing/group/toOrder',
					query: {
						id: this.goods_info.id,
						activity_id: this.activity_id,
						num: this.num,
						type: 1, // 团购
					}
				})
			}else{
				this.$router.push({
					path: '/activity/marketing/group/toOrder',
					query: {
						id: this.goods_info.id,
						activity_id: this.activity_id,
						num: this.num,
						type: 1, // 团购
						group_id: group_id
					}
				})
			}
			
			
		},
		pin(group_id){
			this.group(group_id)
		},
		toactivity(){
			this.$router.push({path:'/activity',query:{id:this.activity_id}})
		},
		myorder(){
			this.$router.push({path: '/activity/marketing/group/order', query:{activity_id: this.activity_id}})
		},
	}
}
</script>

<style scoped lang="less">
	.group-detail-bottom{
	position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    background-color: white;
    width: 100%;
    overflow: hidden;
	text-align:center;
	z-index: 10;
	height: 50px;
	align-items: center;
	
	.group-detail-bottom-item{
		display: inline-block;
		font-size: 10px;
		width: 17%;
		div{
			margin-top: 3px;
		}
	}
	.group-detail-bottom-button{
		display: flex;
		font-size: 13px;
		color: white;
		width: 33%;
		height: 100%;
		flex-flow: wrap;
		align-items: center;
		
		div{
			width: 100%;
		}
	}
	}
	
	.from-group-item{
		&::after{
			border: none;
		}
	}
	
	.detail{
		/deep/ img{
			width: 100% !important;
		}
	}
	
	.goods_top{
		border-radius: 15px 15px 0 0;
		overflow: hidden;
		margin-top: -10px;
	}
</style>
